<!-- Inner Banner -->
<div class="inner-banner">
    <div class="container-fluid">
        <div
            class="row align-items-center"
            
        >
            <div class="col-lg-7 col-md-8">
                <div class="inner-title">
                    <h2>{{ 'page.inner_testimonials' | translate }}</h2>
                    <ul>
                        <li>
                            <a routerLink="/">{{ 'page.inner_home' | translate }}</a>
                        </li>
                        <li>{{ 'page.inner_testimonials' | translate }}</li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-5 col-md-4">
                <div class="inner-img">
                    <img src="assets/images/inner-banner/inner-banner2.png" alt="inner-banner" />
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Testimonials -->
<div class="testimonial-area pt-100 pb-75">
    <div class="container">
        <div class="row">
            <div
                class="col-lg-4 col-md-6 col-sm-6"
                *ngFor="let content of testimonioCard"
            >
                <div class="testimonial-item">
                    <img [src]="content.img" alt="testimonial" />
                    <h3>{{content.name}}</h3>
                    <p>{{content.desc}}</p>
                    <div class="rating">
                        <i
                            class="{{star.icon}}"
                            *ngFor="let star of content.rating"
                        ></i>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
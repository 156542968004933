import { Component, OnInit } from '@angular/core';
import { Router, NavigationCancel, NavigationEnd } from '@angular/router';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { filter } from 'rxjs/operators';
import { LoaderService } from './services/loader.service';
declare let $: any;

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    providers: [
        Location, {
            provide: LocationStrategy,
            useClass: PathLocationStrategy
        }
    ]
})
export class AppComponent implements OnInit {
    location: any;
    routerSubscription: any;
    title: any;
    loader: boolean = true;

    constructor(
        private router: Router,
        private loaderServ: LoaderService
    ) {
        this.loaderServ.subject.subscribe((value) => {
            this.loader = value;
          });
    }

    ngOnInit(){
        this.recallJsFuntions();
        window.addEventListener('scroll', function () {
            if (window.scrollY < 0) {
              window.scrollTo(0, 0); // Fuerza la posición de desplazamiento a la parte superior
            }
          });
          setTimeout(() => {
            this.loaderServ.subject.next(false);
          }, 2000);
    }

    recallJsFuntions() {
        this.routerSubscription = this.router.events
        .pipe(filter(event => event instanceof NavigationEnd || event instanceof NavigationCancel))
        .subscribe(event => {
            this.location = this.router.url;
            if (!(event instanceof NavigationEnd)) {
                return;
            }
            window.scrollTo(0, 0);
        });
    }
}
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { alertI } from '../../interfaces/alert.interface';

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  public showAlert = new BehaviorSubject<alertI>({ isOpen: false, type: 'error', title: '', message: '' });

  constructor() { }
}

<!-- Default Section -->
<div
    class="booking-area pt-100 pb-75"
    [ngClass]="{'d-none': router.url === '/index-2' || router.url === '/index-3' || router.url === '/about' || router.url === '/appointment'}"
>
    <div class="container"> 

        <p>MB Salon & SPA - {{ 'appointment.salonh' | translate }}</p>

        <form [formGroup]="bookingForm" (ngSubmit)="booking()" id="booking-form" action="#" method="POST">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-12">
                    <div class="book-img">
                        <img src="assets/images/masaje.jpeg" alt="book" />
                    </div>
                </div>
                <div class="col-lg-6 col-md-12">
                    <div class="booking-form text-center">
                        <div class="section-title">
                            <span class="sub-title">{{ 'appointment.title' | translate }}</span>
                            <h2>{{ 'appointment.subtitle' | translate }}</h2>
                        </div>
                        <div class="row">
                            <div class="col-lg-6 col-md-6 col-sm-6">
                                <div class="form-group">
                                    <input id="name" [class.is-inpt-invalid]="isNameValid" autocomplete="off" formControlName="name" type="text" class="form-control" placeholder="{{ 'appointment.name' | translate }}">
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-6">
                                <div class="form-group">
                                    <input id="email" [class.is-inpt-invalid]="isEmailValid" autocomplete="off" formControlName="email" type="email" class="form-control" placeholder="{{ 'appointment.mail' | translate }}">
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-6">
                                <div class="form-group">
                                    <select class="form-select form-control" [class.is-inpt-invalid]="isTypeValid" id="type" formControlName="type">
                                        <option selected disabled>{{ 'appointment.type' | translate }}</option>
                                        <option value="sitio">{{ 'appointment.site' | translate }}</option>
                                        <option value="domicilio">{{ 'appointment.dom' | translate }}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-6">
                                <div class="form-group">
                                    <select [class.is-inpt-invalid]="isServiceValid" class="form-select form-control" id="service" formControlName="service">
                                        <option selected disabled>{{ 'banner-form.service' | translate }}</option>
                                        <option value="salon">{{ 'banner-form.s1' | translate }}</option>
                                        <option value="peinado y maquillaje">{{ 'banner-form.s2' | translate }}</option>
                                        <option value="peinado">{{ 'banner-form.s11' | translate }}</option>
                                        <option value="maquillaje">{{ 'banner-form.s12' | translate }}</option>
                                        <option value="color">{{ 'banner-form.s3' | translate }}</option>
                                        <option value="depilacion con cera">{{ 'banner-form.s4' | translate }}</option>
                                        <option value="tratamientos para cabello">{{ 'banner-form.s5' | translate }}</option>
                                        <option value="spa">{{ 'banner-form.s6' | translate }}</option>
                                        <option value="manicure">{{ 'banner-form.s7' | translate }}</option>
                                        <option value="pedicure">{{ 'banner-form.s8' | translate }}</option>
                                        <option value="bodas">{{ 'banner-form.s9' | translate }}</option>
                                        <option value="bienestar">{{ 'banner-form.s10' | translate }}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-6">
                                <div class="form-group">
                                    <input class="form-control" id="phone" autocomplete="off" [class.is-inpt-invalid]="isPhoneValid" formControlName="phone" type="text" class="form-control" placeholder="{{ 'appointment.phone' | translate }}">
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-6">
                                <div class="form-group">
                                    <select [class.is-inpt-invalid]="isServiceValid" class="form-select form-control" id="pax" formControlName="pax">
                                        <option selected disabled>{{ 'appointment.pax' | translate }}</option>
                                        <option value="1">{{ 'appointment.pax1' | translate }}</option>
                                        <option value="2">{{ 'appointment.pax2' | translate }}</option>
                                        <option value="3">{{ 'appointment.pax3' | translate }}</option>
                                        <option value="4">{{ 'appointment.pax4' | translate }}</option>
                                        <option value="5">{{ 'appointment.pax5' | translate }}</option>
                                        <option value="6">{{ 'appointment.pax6' | translate }}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-6">
                                <div class="form-group">
                                    <input class="form-control" id="date" [class.is-inpt-invalid]="isDateValid" formControlName="date" type="text" placeholder="{{ 'appointment.date' | translate }}" bsDatepicker>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-6">
                                <div class="form-group">
                                    <input class="form-control" id="hour" [class.is-inpt-invalid]="isHourValid" formControlName="hour" type="time" placeholder="{{ 'appointment.hour' | translate }}">
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12 col-sm-12">
                                <div class="form-group">
                                    <input id="address" [class.is-inpt-invalid]="isAddressValid" formControlName="address" type="text" class="form-control" placeholder="{{ 'appointment.address' | translate }}">
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12 col-sm-12">
                                <div class="form-group">
                                    <textarea id="comments" [class.is-inpt-invalid]="isCommentsValid" formControlName="comments" name="comments" class="form-control" cols="30" rows="5" placeholder="{{ 'appointment.message' | translate }}"></textarea>
                                </div>
                            </div>
                            <div class="pb-4 captcha text-center">
                                {{ "captcha.one" | translate }}
                                <a href="https://policies.google.com/privacy" target="_blank">{{ "captcha.two" | translate }}</a> {{ "captcha.three" | translate }}
                                <a href="https://policies.google.com/terms" target="_blank">{{ "captcha.four" | translate }}</a> {{ "captcha.five" | translate }}
                            </div>
                            <div class="col-lg-12 col-md-12 col-sm-12 text-center">
                                <button type="submit" class="default-btn">{{ 'appointment.btn' | translate }}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
        

    </div>
</div>

<!-- Section for Home Demo - 2 -->
<div
    class="booking-area-two pt-100 pb-75 d-none"
    [ngClass]="{'d-block': router.url === '/index-2' || router.url === '/about' || router.url === '/appointment'}"
>
<div class="container">

    <p>MB Salon & SPA - {{ 'appointment.salonb' | translate }}</p>
    <form [formGroup]="bookingForm" (ngSubmit)="booking()" id="booking-form" action="#" method="POST">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="book-img">
                    <img src="assets/images/masaje.jpeg" alt="book" />
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="booking-form">
                    <div class="section-title text-center">
                        <span class="sub-title">{{ 'appointment.title' | translate }}</span>
                        <h2>{{ 'appointment.subtitle' | translate }}</h2>
                    </div>
                    <div class="row">
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="form-group">
                                <input id="name" [class.is-inpt-invalid]="isNameValid" autocomplete="off" formControlName="name" type="text" class="form-control" placeholder="{{ 'appointment.name' | translate }}">
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="form-group">
                                <input id="email" [class.is-inpt-invalid]="isEmailValid" autocomplete="off" formControlName="email" type="email" class="form-control" placeholder="{{ 'appointment.mail' | translate }}">
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="form-group">
                                <select class="form-select form-control" [class.is-inpt-invalid]="isTypeValid" id="type" formControlName="type">
                                    <option selected disabled>{{ 'appointment.type' | translate }}</option>
                                    <option value="sitio">{{ 'appointment.site' | translate }}</option>
                                    <option value="domicilio">{{ 'appointment.dom' | translate }}</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="form-group">
                                <select [class.is-inpt-invalid]="isServiceValid" class="form-select form-control" id="service" formControlName="service">
                                    <option selected disabled>{{ 'banner-form.service' | translate }}</option>
                                    <option value="salon">{{ 'banner-form.s1' | translate }}</option>
                                    <option value="peinado_maquillaje">{{ 'banner-form.s2' | translate }}</option>
                                    <option value="color">{{ 'banner-form.s3' | translate }}</option>
                                    <option value="cera">{{ 'banner-form.s4' | translate }}</option>
                                    <option value="tratamientos">{{ 'banner-form.s5' | translate }}</option>
                                    <option value="spa">{{ 'banner-form.s6' | translate }}</option>
                                    <option value="manicure">{{ 'banner-form.s7' | translate }}</option>
                                    <option value="pedicure">{{ 'banner-form.s8' | translate }}</option>
                                    <option value="bodas">{{ 'banner-form.s9' | translate }}</option>
                                    <option value="bienestar">{{ 'banner-form.s10' | translate }}</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="form-group">
                                <input class="form-control" id="phone" autocomplete="off" [class.is-inpt-invalid]="isPhoneValid" formControlName="phone" type="number" class="form-control" placeholder="{{ 'appointment.phone' | translate }}">
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="form-group">
                                <select [class.is-inpt-invalid]="isServiceValid" class="form-select form-control" id="pax" formControlName="pax">
                                    <option selected disabled>{{ 'appointment.pax' | translate }}</option>
                                    <option value="1">{{ 'appointment.pax1' | translate }}</option>
                                    <option value="2">{{ 'appointment.pax2' | translate }}</option>
                                    <option value="3">{{ 'appointment.pax3' | translate }}</option>
                                    <option value="4">{{ 'appointment.pax4' | translate }}</option>
                                    <option value="5">{{ 'appointment.pax5' | translate }}</option>
                                    <option value="6">{{ 'appointment.pax6' | translate }}</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="form-group">
                                <input class="form-control" id="date" [class.is-inpt-invalid]="isDateValid" formControlName="date" type="text" placeholder="{{ 'appointment.date' | translate }}" bsDatepicker>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="form-group">
                                <input class="form-control" id="hour" [class.is-inpt-invalid]="isHourValid" formControlName="hour" type="time" placeholder="{{ 'appointment.hour' | translate }}">
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12 col-sm-12">
                            <div class="form-group">
                                <input id="address" [class.is-inpt-invalid]="isAddressValid" formControlName="address" type="text" class="form-control" placeholder="{{ 'appointment.address' | translate }}">
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12 col-sm-12">
                            <div class="form-group">
                                <textarea id="comments" [class.is-inpt-invalid]="isCommentsValid" formControlName="comments" name="comments" class="form-control" cols="30" rows="5" placeholder="{{ 'appointment.message' | translate }}"></textarea>
                            </div>
                        </div>
                        <div class="pb-4 captcha text-center">
                            {{ "captcha.one" | translate }}
                            <a href="https://policies.google.com/privacy">{{ "captcha.two" | translate }}</a> {{ "captcha.three" | translate }}
                            <a href="https://policies.google.com/terms">{{ "captcha.four" | translate }}</a> {{ "captcha.five" | translate }}
                        </div>
                        <div class="col-lg-12 col-md-12 col-sm-12 text-center">
                            <button type="submit" class="default-btn">{{ 'appointment.btn' | translate }}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
    
</div>
</div>

<!--MODAL-->

<app-alert></app-alert>


import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home-demo-three',
  templateUrl: './home-demo-three.component.html',
  styleUrls: ['./home-demo-three.component.scss']
})
export class HomeDemoThreeComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}

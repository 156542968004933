import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ViewportScroller } from '@angular/common';

@Component({
    selector: 'app-faq-page',
    templateUrl: './faq-page.component.html',
    styleUrls: ['./faq-page.component.scss']
})
export class FaqPageComponent implements OnInit {

    oneAtATime = true;
    faqs: string = '';

    faqCard: { 
        title: string; 
        answer: string; }[][] = [];
  
    translateSub: any;

    constructor(
      private _translateService: TranslateService, 
      private route: ActivatedRoute, 
      private scroller: ViewportScroller, 
      private router: Router
    ) { }

    ngOnInit() {
        this.faqs = this.route.snapshot.params['id'];
    
        this.translateSub = this._translateService.stream('faqAccordion').subscribe(data => {
          const foundCards = data.filter((el: any) => el.faq.some((item: any) => item.id === this.faqs));
          if (foundCards.length > 0) {
            const items = foundCards.flatMap((el: any) => el.faq);
            this.faqCard = this.chunkArray(items, 5); // Dividir en subarrays
          } else {
            this.router.navigate(['not-found']);
          }
        });
      }
    
      scrollDown() {
        this.scroller.scrollToAnchor("firstSection");
      }
    
      ngOnDestroy() {
        if (this.translateSub) {
          this.translateSub.unsubscribe();
        }
      }
    
      chunkArray(myArray: any[], chunk_size: number): any[][] {
        const results = [];
        while (myArray.length) {
          results.push(myArray.splice(0, chunk_size));
        }
        return results;
      }
    

}
<!-- Default Section -->
<div
    class="testimonial-area section-bg pt-100 pb-75">
    <div class="container">
        <div
            class="section-title text-center">
            <span class="sub-title">{{ 'page.testimonials_subtitle' | translate }}</span>
            <h2>{{ 'page.testimonials_title' | translate }}</h2>
        </div>
        <div class="testimonial-slider">
            <owl-carousel-o [options]="testimonialSlides">
                <ng-template
                    carouselSlide
                    *ngFor="let content of testimonialCard"
                >
                    <div class="testimonial-item">
                        <img [src]="content.img" alt="testimonial" />
                        <h3>{{content.name}}</h3>
                        <p>{{content.desc}}</p>
                        <div class="rating">
                            <i
                                class="{{star.icon}}"
                                *ngFor="let star of content.rating"
                            ></i>
                        </div>
                    </div>
                </ng-template>
            </owl-carousel-o>
        </div>
    </div>
</div>


import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ViewportScroller } from '@angular/common';

@Component({
  selector: 'app-packages-detail',
  templateUrl: './packages-detail.component.html',
  styleUrls: ['./packages-detail.component.scss']
})
export class PackagesDetailComponent implements OnInit, OnDestroy {

  pricing: string = '';

  pricingCard: { image: string; title: string; desc: string; price: string; }[][] = [];

  translateSub: any;

  constructor(
    private _translateService: TranslateService, 
    private route: ActivatedRoute, 
    private scroller: ViewportScroller, 
    private router: Router
  ) {}

  ngOnInit() {
    this.pricing = this.route.snapshot.params['id'];

    this.translateSub = this._translateService.stream('pricingCard').subscribe(data => {
      const foundCards = data.filter((el: any) => el.text.some((item: any) => item.id === this.pricing));
      if (foundCards.length > 0) {
        const items = foundCards.flatMap((el: any) => el.text);
        this.pricingCard = this.chunkArray(items, 3); // Dividir en subarrays de 3 elementos
      } else {
        this.router.navigate(['not-found']);
      }
    });
  }

  scrollDown() {
    this.scroller.scrollToAnchor("firstSection");
  }

  ngOnDestroy() {
    if (this.translateSub) {
      this.translateSub.unsubscribe();
    }
  }

  chunkArray(myArray: any[], chunk_size: number): any[][] {
    const results = [];
    while (myArray.length) {
      results.push(myArray.splice(0, chunk_size));
    }
    return results;
  }
}

<!-- Default Section -->
<div
    class="intro-video-area pb-100"
    style="background-image: url(assets/images/video/video-img.png);"
    [ngClass]="{'d-none': router.url === '/index-2' || router.url === '/index-3'}"
>
    <div class="container">
        <div class="video-content">
            <img src="assets/images/youtube_min.png" alt="video-image">
            <button class="play-btn" (click)="openPopup()">
                <i class="ri-play-fill"></i>
            </button>
        </div>
        <div class="section-title text-center pt-100">
            <span class="sub-title">{{ 'page.video_subtitle' | translate }}</span>
            <h2>{{ 'page.video_title' | translate }}</h2>
        </div>
    </div>
</div>



<!-- Video Popup -->
<div class="video-popup" *ngIf="isOpen">
    <div class="popup-inner">
        <iframe src="https://www.youtube.com/embed/hP72prShca4?si=Bkn8RTnX1fQJySXh" title="Setup and Local Installation - React Nextjs Online Yoga Coaching &amp; Training Courses System" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
        <button (click)="closePopup()" type="button" class="close-btn">
            <i class="ri-close-line"></i>
        </button>
    </div>
</div>
import { NgModule } from '@angular/core';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { ModalModule } from 'ngx-bootstrap/modal';
import { NotifierModule } from 'angular-notifier';
import { NgxScrollTopModule } from 'ngx-scrolltop';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { NgxPaginationModule } from 'ngx-pagination';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { BrowserModule } from '@angular/platform-browser';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PackagesComponent } from './components/pages/packages/packages.component';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeDemoThreeComponent } from './components/pages/home-demo-three/home-demo-three.component';
import { NavbarComponent } from './components/common/navbar/navbar.component';
import { FooterComponent } from './components/common/footer/footer.component';
import { NotFoundComponent } from './components/common/not-found/not-found.component';
import { AppointmentComponent } from './components/common/appointment/appointment.component';
import { FeedbackComponent } from './components/common/feedback/feedback.component';
import { StaffComponent } from './components/common/staff/staff.component';
import { IntroVideoComponent } from './components/common/intro-video/intro-video.component';
import { ServicesComponent } from './components/common/services/services.component';
import { AboutComponent } from './components/common/about/about.component';
import { FeaturedServicesComponent } from './components/common/featured-services/featured-services.component';
import { TopHeaderComponent } from './components/common/top-header/top-header.component';
import { HomethreeBannerComponent } from './components/pages/home-demo-three/homethree-banner/homethree-banner.component';
import { BannerFormComponent } from './components/common/banner-form/banner-form.component';
import { AboutPageComponent } from './components/pages/about-page/about-page.component';
import { TestimonialsPageComponent } from './components/pages/testimonials-page/testimonials-page.component';
import { FaqPageComponent } from './components/pages/faq-page/faq-page.component';
import { AppointmentPageComponent } from './components/pages/appointment-page/appointment-page.component';
import { PrivacyPolicyPageComponent } from './components/pages/privacy-policy-page/privacy-policy-page.component';
import { TermsConditionsPageComponent } from './components/pages/terms-conditions-page/terms-conditions-page.component';
import { ServicesPageOneComponent } from './components/pages/services-page-one/services-page-one.component';
import { ServiceDetailsPageComponent } from './components/pages/service-details-page/service-details-page.component';
import { ContactPageComponent } from './components/pages/contact-page/contact-page.component';
import { CustomerServicePageComponent } from './components/pages/customer-service-page/customer-service-page.component';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { PackagesDetailComponent } from './components/pages/packages-detail/packages-detail.component';
import { ServiceDetailsPagesComponent } from './components/pages/service-details-pages/service-details-pages.component';
import { ServiceDetailPagesComponent } from './components/pages/service-detail-pages/service-detail-pages.component';
import { ServiceDetailPageComponent } from './components/pages/service-detail-page/service-detail-page.component';
import { AlertModule } from './components/alert/alert.module';
import { ContactComponent } from './components/common/contact/contact.component';
import { LoaderComponent } from './components/common/loader/loader.component';

export function HttpLoaderFactory(httpClient: HttpClient) {
    return new TranslateHttpLoader(httpClient);
  }

@NgModule({
    declarations: [
        AppComponent,
        PackagesComponent,
        HomeDemoThreeComponent,
        NavbarComponent,
        FooterComponent,
        NotFoundComponent,
        AppointmentComponent,
        FeedbackComponent,
        StaffComponent,
        IntroVideoComponent,
        ServicesComponent,
        AboutComponent,
        FeaturedServicesComponent,
        TopHeaderComponent,
        HomethreeBannerComponent,
        BannerFormComponent,
        AboutPageComponent,
        TestimonialsPageComponent,
        FaqPageComponent,
        AppointmentPageComponent,
        PrivacyPolicyPageComponent,
        TermsConditionsPageComponent,
        ServicesPageOneComponent,
        ServiceDetailsPageComponent,
        ContactPageComponent,
        CustomerServicePageComponent,
        PackagesDetailComponent,
        ServiceDetailsPagesComponent,
        ServiceDetailPagesComponent,
        ServiceDetailPageComponent,
        ContactComponent,
        LoaderComponent,
    ],
    imports: [
        BrowserModule,
        AlertModule,
        AppRoutingModule,
        CarouselModule,
        BrowserAnimationsModule,
        NgxScrollTopModule,
        ModalModule.forRoot(),
        TabsModule.forRoot(),
        AccordionModule.forRoot(),
        BsDatepickerModule.forRoot(),
        TimepickerModule.forRoot(),
        FormsModule,
        NgxPaginationModule,
        HttpClientModule,
        NotifierModule,
        ReactiveFormsModule,
        TranslateModule.forRoot({
            defaultLanguage: 'es',
            loader: {
              provide: TranslateLoader,
              useFactory: HttpLoaderFactory,
              deps: [HttpClient],
            },
          }),
    ],
    exports: [],
    providers: [],
    bootstrap: [AppComponent]
})
export class AppModule { }
<!-- Inner Banner -->
<div class="inner-banner">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-lg-7 col-md-8">
                <div class="inner-title">
                    <h2>{{ 'page.catalog_title' | translate }}</h2>
                    <ul>
                        <li>
                            <a routerLink="/">{{ 'page.inner_home' | translate }}</a>
                        </li>
                        <li>{{ 'page.catalog_title' | translate }}</li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-5 col-md-4">
                <div class="inner-img">
                    <img src="assets/images/inner-banner/inner-banner1.png" alt="inner-banner" />
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Default Section -->
<div class="pricing-area section-bg pt-100 pb-75">
    <div class="container">
        <div class="row">
            <!-- Iterar sobre subarrays en pricingCard -->
            <div class="col-lg-6 col-md-6 col-sm-6" *ngFor="let pricingGroup of pricingCard">
                <div class="pricing-card">
                    <ul>
                        <!-- Iterar sobre los elementos dentro de cada subarray -->
                        <li *ngFor="let pricing of pricingGroup">
                            <img [src]="pricing.image" alt="pricing" />
                            <div class="content">
                                <h3>{{pricing.title}}<span>{{pricing.price}}</span></h3>
                                <p>{{pricing.desc}}</p>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="error-area ptb-100">
    <div class="container">
        <div class="error-content">
            <h1>4<span>0</span>4</h1>
            <h3> {{'404.title' | translate}}</h3>
            <p> {{'404.desc' | translate}}</p>
            <a routerLink="/" class="default-btn">
                {{'404.btn' | translate}}
            </a>
        </div>
    </div>
</div>
<div class="top-header-area">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-lg-8 col-md-12">
                <ul class="top-header-info">
                    <li>
                        <i class="flaticon-195-envelope"></i>
                        <a href="mailto:info@mireyabeltransalon.com">info@mireyabeltransalon.com</a>
                    </li>
                    <li>
                        <i class="flaticon-020-phone-call"></i>
                        <a href="tel:+526241227019">+52 (624) 122 7019</a>
                    </li>
                    <li>
                        <i class="flaticon-048-clock"></i>
                        {{ 'navbar.schedule' | translate }}
                    </li>
                </ul>
            </div>
        <!--    <div class="col-lg-4 col-md-12">
                <ul class="social-links">
                    <li>
                        <a href="https://www.facebook.com/MireyaBeltranSalon" target="_blank">
                            <i class="flaticon-001-facebook"></i>
                        </a>
                    </li>
                    <li>
                        <a href="https://x.com/mireyabsalonspa" target="_blank">
                            <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24"><path fill="currentColor" d="M18.205 2.25h3.308l-7.227 8.26l8.502 11.24H16.13l-5.214-6.817L4.95 21.75H1.64l7.73-8.835L1.215 2.25H8.04l4.713 6.231zm-1.161 17.52h1.833L7.045 4.126H5.078z"/></svg>
                        </a>
                    </li>
                    <li>
                        <a href="https://mx.linkedin.com/company/mireya-beltran-salon-spa" target="_blank">
                            <i class="flaticon-016-linkedin"></i>
                        </a>
                    </li>
                    <li>
                        <a href="https://www.youtube.com/@MireyaBeltranSalonSPA" target="_blank">
                            <i class="flaticon-008-youtube"></i>
                        </a>
                    </li>
                </ul>
            </div> -->

            <div class="col-lg-4 col-md-12">
                <ul class="social-links">
                    <li>
                        <a href="https://www.facebook.com/MireyaBeltranSalon" target="_blank">
                            <i class="flaticon-001-facebook"></i>
                        </a>
                    </li>
                    <li>
                        <a href="https://x.com/mireyabsalonspa" target="_blank">
                            <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" style="margin-top: 30%;">
                                <path fill="currentColor" d="M18.205 2.25h3.308l-7.227 8.26l8.502 11.24H16.13l-5.214-6.817L4.95 21.75H1.64l7.73-8.835L1.215 2.25H8.04l4.713 6.231zm-1.161 17.52h1.833L7.045 4.126H5.078z"/>
                            </svg>
                        </a>
                    </li>
                    <li>
                        <a href="https://mx.linkedin.com/company/mireya-beltran-salon-spa" target="_blank">
                            <i class="flaticon-016-linkedin"></i>
                        </a>
                    </li>
                    <li>
                        <a href="https://www.youtube.com/@MireyaBeltranSalonSPA" target="_blank">
                            <i class="flaticon-008-youtube"></i>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>
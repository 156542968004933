import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CaptchaService } from '../../../services/captcha/captcha.service';
import { EmailService } from '../../../services/email/email.service';
import { AlertService } from '../../../services/alert/alert.service';
import { TranslateService } from '@ngx-translate/core';
import { LoaderService } from 'src/app/services/loader.service';

@Component({
    selector: 'app-appointment',
    templateUrl: './appointment.component.html',
    styleUrls: ['./appointment.component.scss']
})
export class AppointmentComponent implements OnInit, OnDestroy {

    captchaAction: string = 'MBS_email_form';
    bookingForm: FormGroup = this.formBuilder.group({});
    openAlert: boolean = false;
    contactMessages: any;
    translateSub: any;

    constructor(
        public router: Router,
        private _captchaService: CaptchaService,
        private formBuilder: FormBuilder,
        private _emailService: EmailService,
        private _alertService: AlertService,
        private translate: TranslateService,
        private _loaderService: LoaderService
    ) { 
        this.initForm();
    }

    ngOnInit() {
        this.translateSub = this.translate
            .stream('messages')
            .subscribe((data: any) => {
                this.contactMessages = data;
            });

        this.onChanges();
    }

    initForm(): void {
        this.bookingForm = this.formBuilder.group({
            name: ['', [Validators.minLength(2)]],
            email: [
                '',
                [
                    Validators.required,
                    Validators.pattern('^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,4}$'),
                ],
            ],
            type: ['', [Validators.required, Validators.minLength(2)]],
            service: ['', [Validators.required, Validators.minLength(2)]],
            phone: ['', [Validators.required, Validators.minLength(2)]],
            pax: ['', [Validators.required, Validators.minLength(1)]],
            date: ['', [Validators.required, Validators.minLength(1)]],
            hour: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(10)]],
            address: ['', [Validators.minLength(2)]],
            comments: ['', [Validators.minLength(2)]],
        });
    }

    onChanges(): void {
        this.bookingForm.get('type')?.valueChanges.subscribe(value => {
            const addressControl = this.bookingForm.get('address');
            if (value === 'domicilio') {
                addressControl?.setValidators([Validators.required, Validators.minLength(2)]);
            } else {
                addressControl?.setValidators([Validators.minLength(2)]);
            }
            addressControl?.updateValueAndValidity();
        });
    }

    checkInvalidFields(): void {
        if (this.bookingForm.invalid) {
            Object.values(this.bookingForm.controls).forEach((control) => {
                control.markAsTouched();
            });
        }
    }

    get isNameValid(): boolean | undefined {
        return this.bookingForm.get('name')?.invalid && this.bookingForm.get('name')?.touched;
    }

    get isEmailValid(): boolean | undefined {
        return this.bookingForm.get('email')?.invalid && this.bookingForm.get('email')?.touched;
    }

    get isTypeValid(): boolean | undefined {
        return this.bookingForm.get('type')?.invalid && this.bookingForm.get('type')?.touched;
    }

    get isServiceValid(): boolean | undefined {
        return this.bookingForm.get('service')?.invalid && this.bookingForm.get('service')?.touched;
    }

    get isPhoneValid(): boolean | undefined {
        return this.bookingForm.get('phone')?.invalid && this.bookingForm.get('phone')?.touched;
    }

    get isPaxValid(): boolean | undefined {
        return this.bookingForm.get('pax')?.invalid && this.bookingForm.get('pax')?.touched;
    }

    get isDateValid(): boolean | undefined {
        return this.bookingForm.get('date')?.invalid && this.bookingForm.get('date')?.touched;
    }

    get isHourValid(): boolean | undefined {
        return this.bookingForm.get('hour')?.invalid && this.bookingForm.get('hour')?.touched;
    }

    get isAddressValid(): boolean | undefined {
        return this.bookingForm.get('address')?.invalid && this.bookingForm.get('address')?.touched;
    }

    get isCommentsValid(): boolean | undefined {
        return this.bookingForm.get('comments')?.invalid && this.bookingForm.get('comments')?.touched;
    }

    

    


    

    

    async booking() {
        try {
            if (this.bookingForm.invalid) {
                return this.checkInvalidFields();
            }

            this._loaderService.subject.next(true);

            const recaptcha: string = await this._captchaService.executeAction(this.captchaAction);

            const emailData: any = {
                name: this.bookingForm.get('name')?.value,
                email: this.bookingForm.get('email')?.value,
                type: this.bookingForm.get('type')?.value,
                service: this.bookingForm.get('service')?.value,
                phone: this.bookingForm.get('phone')?.value,
                pax: this.bookingForm.get('pax')?.value,
                date: this.bookingForm.get('date')?.value,
                hour: this.bookingForm.get('hour')?.value,
                address: this.bookingForm.get('address')?.value,
                comments: this.bookingForm.get('comments')?.value,
            //    process: 'bookingForm',
                recaptchaToken: recaptcha,
                recaptchaAction: this.captchaAction,
            };

            const emailContactSub = this._emailService
                .booking(emailData)
                .subscribe({
                    next: (data) => {
                        emailContactSub.unsubscribe();
                        if (data.status === 'success') {
                            this.bookingForm.reset();
                            this._alertService.showAlert.next({
                                isOpen: true,
                                type: 'success',
                                title: this.contactMessages.contact.send,
                                message: this.contactMessages.contact.success,
                            });
                        } else {
                            this._alertService.showAlert.next({
                                isOpen: true,
                                type: 'error',
                                title: this.contactMessages.error,
                                message: this.contactMessages.contact.error,
                            });
                        }
                    },
                    error: (error) => {
                        emailContactSub.unsubscribe();
                        this._alertService.showAlert.next({
                            isOpen: true,
                            type: 'error',
                            title: this.contactMessages.error,
                            message: this.contactMessages.contact.ups,
                        });
                    },
                });
        } catch (e) {
            this._alertService.showAlert.next({
                isOpen: true,
                type: 'error',
                title: this.contactMessages.error,
                message: this.contactMessages.contact.ups,
            });
        } finally {
            this._loaderService.subject.next(false);
          }
    }

    mytime: Date = new Date();

    ngOnDestroy() {
        if (this.translateSub) {
            this.translateSub.unsubscribe();
        }
    this._alertService.showAlert.next({
        isOpen: false,
        type: 'error',
        title: '',
        message: ''
    });
    }
}

import { environment } from 'src/environments/environment';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CaptchaService } from '../../../services/captcha/captcha.service';
import { EmailService } from '../../../services/email/email.service';
import { AlertService } from '../../../services/alert/alert.service';
import { TranslateService } from '@ngx-translate/core';

declare global {
  interface Window {
    mapkit: any;
    initMapKit?: () => void;
  }
}

@Component({
  selector: 'app-contact-page',
  templateUrl: './contact-page.component.html',
  styleUrls: ['./contact-page.component.scss']
})
export class ContactPageComponent implements OnInit, OnDestroy {

  captchaAction: string = 'MBS_email_form';
    contactoForm: FormGroup = this.formBuilder.group({});
    openAlert: boolean = false;
    contactMessages: any;
    translateSub: any;

  constructor(
        public router: Router,
        private _captchaService: CaptchaService,
        private formBuilder: FormBuilder,
        private _emailService: EmailService,
        private _alertService: AlertService,
        private translate: TranslateService,) { }

  ngOnInit() {
    this.loadMapKitScript();
    this.initForm();
    this.translateSub = this.translate
            .stream('messages')
            .subscribe((data: any) => {
                this.contactMessages = data;
            });
  }

  initForm(): void {
    this.contactoForm = this.formBuilder.group({
      name: ['', [Validators.required, Validators.minLength(2)]],
      email: [
        '',
        [
          Validators.required,
          Validators.pattern('^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,4}$'),
        ],
      ],
      phone: ['', [Validators.required, Validators.minLength(2)]],
      subject: ['', [Validators.required, Validators.minLength(2)]],
      message: ['', [Validators.required, Validators.minLength(2)]],
    });
  }

  async loadMapKitScript() {
    const script = document.createElement('script');
    script.src = 'https://cdn.apple-mapkit.com/mk/5.x.x/mapkit.js';
    script.async = true;
    script.onload = () => {
      
      if (window.initMapKit) {
        window.initMapKit(); // Llama a initMapKit sin argumentos
      } else {
        this.setupMapKitJs();
      }
    };
    script.onerror = (error) => {
      console.error('Error loading MapKit:', error);
    };
    document.head.appendChild(script);
  }

  async setupMapKitJs() {
    const jwt = environment.mapToken
    window.mapkit.init({
      authorizationCallback: (done: (arg0: string) => void) => { done(jwt); }
    });

    this.createMap();
  }

  createMap() {
    const map = new window.mapkit.Map("map-container");
    const geocoder = new window.mapkit.Geocoder({ language: "es-MX" });

    

    const work = new window.mapkit.Coordinate(23.042729, -109.701635); 
    const workAnnotation = new window.mapkit.MarkerAnnotation(work);
    workAnnotation.color = "#969696";
    workAnnotation.title = "Mireya Beltran";
    workAnnotation.subtitle = "Salon & SPA";
    workAnnotation.selected = "true";
    workAnnotation.glyphText = "\u{2702}";

    map.showItems([workAnnotation]);
    map.cameraDistance = 1200;
  }

  

  checkInvalidFields(): void {
    if (this.contactoForm.invalid) {
      Object.values(this.contactoForm.controls).map((control) => {
        control.markAsTouched();
      });
    }
  }

  get isNameValid(): boolean | undefined {
    return (
      this.contactoForm.get('name')?.invalid &&
      this.contactoForm.get('name')?.touched
    );
  }

  get isEmailValid(): boolean | undefined {
    return (
      this.contactoForm.get('email')?.invalid &&
      this.contactoForm.get('email')?.touched
    );
  }

  get isPhoneValid(): boolean | undefined {
    return (
      this.contactoForm.get('phone')?.invalid &&
      this.contactoForm.get('phone')?.touched
    );
  }

  get isSubjectValid(): boolean | undefined {
    return (
      this.contactoForm.get('subject')?.invalid &&
      this.contactoForm.get('subject')?.touched
    );
  }

  get isMessageValid(): boolean | undefined {
    return (
      this.contactoForm.get('message')?.invalid &&
      this.contactoForm.get('message')?.touched
    );
  }

  async contacto() {
    try {
      if (this.contactoForm.invalid) {
        return this.checkInvalidFields();
      }

      const recaptcha: string = await this._captchaService.executeAction(
        this.captchaAction
      );

      const emailData: any = {
        name: this.contactoForm.get('name')?.value,
        email: this.contactoForm.get('email')?.value,
        phone: this.contactoForm.get('phone')?.value,
        subject: this.contactoForm.get('subject')?.value,
        message: this.contactoForm.get('message')?.value,
      //  process: 'contactoForm',
        recaptchaToken: recaptcha,
        recaptchaAction: this.captchaAction,
      };

      const emailContactSub = this._emailService
        .contacto(emailData)
        .subscribe({
          next: (data) => {
            emailContactSub.unsubscribe();
            if (data.status === 'success') {
              this.contactoForm.reset();
              this._alertService.showAlert.next({
                isOpen: true,
                type: 'success',
                title: this.contactMessages.contact.send,
                message: this.contactMessages.contact.success,
              });
            } else {
              this._alertService.showAlert.next({
                isOpen: true,
                type: 'error',
                title: this.contactMessages.error,
                message: this.contactMessages.contact.error,
              });
            }
          },
          error: (error) => {
            emailContactSub.unsubscribe();
            this._alertService.showAlert.next({
              isOpen: true,
              type: 'error',
              title: this.contactMessages.error,
              message: this.contactMessages.contact.ups,
            });
          },
        });
    } catch (e) {
      this._alertService.showAlert.next({
        isOpen: true,
        type: 'error',
        title: this.contactMessages.error,
        message: this.contactMessages.contact.ups,
      });
    }
  }

mytime: Date = new Date();

ngOnDestroy() {
    if (this.translateSub) {
      this.translateSub.unsubscribe();
    }
  }
}

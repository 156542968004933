import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CaptchaService } from '../../../services/captcha/captcha.service';
import { EmailService } from '../../../services/email/email.service';
import { AlertService } from '../../../services/alert/alert.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-banner-form',
    templateUrl: './banner-form.component.html',
    styleUrls: ['./banner-form.component.scss']
})
export class BannerFormComponent implements OnInit {

    captchaAction: string = 'MBS_email_form';
    contactForm: FormGroup = this.formBuilder.group({});
    openAlert: boolean = false;
    contactMessages: any;
    translateSub: any;

    constructor(
    private _captchaService: CaptchaService,
    private formBuilder: FormBuilder,
    private _emailService: EmailService,
    private _alertService: AlertService,
    private translate: TranslateService,
    ) { 
    this.initForm();
    }

    ngOnInit(
        
    ){
        this.translateSub = this.translate
        .stream('messages')
        .subscribe((data: any) => {
          this.contactMessages = data;
        });
    }

    initForm(): void {
        this.contactForm = this.formBuilder.group({
          type: ['', [Validators.required, Validators.minLength(2)]],
          name: ['', [ Validators.minLength(2)]],
          service: ['', [Validators.required, Validators.minLength(2)]],
          date: ['', [Validators.required, Validators.minLength(1)]],
          hour: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(10)]],
          email: [
            '',
            [
              Validators.required,
              Validators.pattern('^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,4}$'),
            ],
          ],
          phone: ['', [Validators.required, Validators.minLength(2)]],
        });
      }

      checkInvalidFields(): void {
        if (this.contactForm.invalid) {
          Object.values(this.contactForm.controls).map((control) => {
            control.markAsTouched();
          });
        }
      }

      get isTypeValid(): boolean | undefined {
        return (
          this.contactForm.get('type')?.invalid &&
          this.contactForm.get('type')?.touched
        );
      }

      get isNameValid(): boolean | undefined {
        return (
          this.contactForm.get('name')?.invalid &&
          this.contactForm.get('name')?.touched
        );
      }
    
      get isServiceValid(): boolean | undefined {
        return (
          this.contactForm.get('service')?.invalid &&
          this.contactForm.get('service')?.touched
        );
      }
    
      get isDateValid(): boolean | undefined {
        return (
          this.contactForm.get('date')?.invalid &&
          this.contactForm.get('date')?.touched
        );
      }

      get isHourValid(): boolean | undefined {
        return (
          this.contactForm.get('hour')?.invalid &&
          this.contactForm.get('hour')?.touched
        );
      }
    
      get isEmailValid(): boolean | undefined {
        return (
          this.contactForm.get('email')?.invalid &&
          this.contactForm.get('email')?.touched
        );
      }
    
      get isPhoneValid(): boolean | undefined {
        return (
          this.contactForm.get('phone')?.invalid &&
          this.contactForm.get('phone')?.touched
        );
      }
    
      async banner() {
        try {
          if (this.contactForm.invalid) {
            return this.checkInvalidFields();
          }
    
          const recaptcha: string = await this._captchaService.executeAction(
            this.captchaAction
          );
    
          const emailData: any = {
            type: this.contactForm.get('type')?.value,
            name: this.contactForm.get('name')?.value,
            service: this.contactForm.get('service')?.value,
            date: this.contactForm.get('date')?.value,
            hour: this.contactForm.get('hour')?.value,
            email: this.contactForm.get('email')?.value,
            phone: this.contactForm.get('phone')?.value,
          //  process: 'contactForm',
            recaptchaToken: recaptcha,
            recaptchaAction: this.captchaAction,
          };
    
          const emailContactSub = this._emailService
            .banner(emailData)
            .subscribe({
              next: (data) => {
                emailContactSub.unsubscribe();
                if (data.status === 'success') {
                  this.contactForm.reset();
                  this._alertService.showAlert.next({
                    isOpen: true,
                    type: 'success',
                    title: this.contactMessages.contact.send,
                    message: this.contactMessages.contact.success,
                  });
                } else {
                  this._alertService.showAlert.next({
                    isOpen: true,
                    type: 'error',
                    title: this.contactMessages.error,
                    message: this.contactMessages.contact.error,
                  });
                }
              },
              error: (error) => {
                emailContactSub.unsubscribe();
                this._alertService.showAlert.next({
                  isOpen: true,
                  type: 'error',
                  title: this.contactMessages.error,
                  message: this.contactMessages.contact.ups,
                });
              },
            });
        } catch (e) {
          this._alertService.showAlert.next({
            isOpen: true,
            type: 'error',
            title: this.contactMessages.error,
            message: this.contactMessages.contact.ups,
          });
        }
      }

    mytime: Date = new Date();

    ngOnDestroy() {
        if (this.translateSub) {
          this.translateSub.unsubscribe();
        }
      }

}
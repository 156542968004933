<!-- Inner Banner -->
<div class="inner-banner">
    <div class="container-fluid">
        <div
            class="row align-items-center">
            <div class="col-lg-7 col-md-8">
                <div class="inner-title">
                    <h2>{{ 'page.inner_faqs' | translate }}</h2>
                    <ul>
                        <li>
                            <a routerLink="/">{{ 'page.inner_home' | translate }}</a>
                        </li>
                        <li>{{ 'page.inner_faqs' | translate }}</li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-5 col-md-4">
                <div class="inner-img">
                    <img src="assets/images/inner-banner/inner-banner3.png" alt="inner-banner" />
                </div>
            </div>
        </div>
    </div>
</div>

<!-- FAQ -->
<div class="faq-area pt-100 pb-75">
    <div class="container">
        <div class="row">
            <div
                class="col-lg-6 col-md-12"
                *ngFor="let content of faqCard">
                <div class="faq-accordion">
                    <accordion [closeOthers]="oneAtATime">
                        <accordion-group
                            heading="{{item.title}}"
                            *ngFor="let item of content">
                            <p>{{item.answer}}</p>
                        </accordion-group>
                    </accordion>
                </div>
            </div>
        </div>
    </div>
</div>

<app-contact></app-contact>
import { OwlOptions } from 'ngx-owl-carousel-o';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ViewportScroller } from '@angular/common';

@Component({
  selector: 'app-homethree-banner',
  templateUrl: './homethree-banner.component.html',
  styleUrls: ['./homethree-banner.component.scss']
})
export class HomethreeBannerComponent implements OnInit, OnDestroy {

  bannerCard: { 
    subtitle: string;
    title: string;
    paragraph: string;
    buttonText: string;
    id: any; 
    bgImage: any; 
  }[] = [];

  translateSub: any;

  constructor(
    public router: Router,
    private _translateService: TranslateService, 
    private scroller: ViewportScroller
  ) { }

  ngOnInit() {
    this.translateSub = this._translateService.stream('bannerContent').subscribe(data => {
     
      this.bannerCard = data;
   
    });
  }

  bannerSlides: OwlOptions = {
    items: 1,
    nav: true,
    loop: true,
    dots: false,
    autoplay: true,
    smartSpeed: 1000,
    animateIn: `fadeIn`,
    animateOut: `fadeOut`,
    autoplayHoverPause: true,
    navText: [
      '<i class="flaticon-029-back"></i>',
      '<i class="flaticon-030-next"></i>'
    ]
  }

  scrollDown() {
    this.scroller.scrollToAnchor("firstSection");
  }

  ngOnDestroy() {
    if (this.translateSub) {
      this.translateSub.unsubscribe();
    }
  }
}

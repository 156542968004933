import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EmailService {

  private readonly url = environment.apiUrl;

  constructor(private http: HttpClient) {}

  banner(data: any): Observable<any> {
    return this.http.post(`${this.url}/v1/email/banner`, data);
  }

  booking(data: any): Observable<any> {
    return this.http.post(`${this.url}/v1/email/booking`, data);
  }

  contacto(data: any): Observable<any> {
    return this.http.post(`${this.url}/v1/email/contactmb`, data);
  }
}

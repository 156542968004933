import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ViewportScroller } from '@angular/common';

@Component({
    selector: 'app-services',
    templateUrl: './services.component.html',
    styleUrls: ['./services.component.scss']
})
export class ServicesComponent implements OnInit, OnDestroy {

    services: string = '';
    servicesCard: { 
        title: string;
        image: any;
        id: any;
        type: string; // Modificación: Añadir tipo de servicio
    }[] = [];
  
    translateSub: any;

    constructor(
        public router: Router,
        private _translateService: TranslateService, 
        private route: ActivatedRoute, 
        private scroller: ViewportScroller,
    ) { }

    ngOnInit() {
        this.services = this.route.snapshot.params['id'];
    
        this.translateSub = this._translateService.stream('servicesCardHome').subscribe(data => {
            this.servicesCard = data.map((card: { type: any; }) => ({
                ...card,
                type: card.type // Asegúrate que cada objeto en `data` tenga un `type`
            }));
        });
    }

    scrollDown() {
        this.scroller.scrollToAnchor("firstSection");
    }
    
    ngOnDestroy() {
        if (this.translateSub) {
          this.translateSub.unsubscribe();
        }
    }
}

<!-- Footer -->
<footer class="footer-area footer-bg pt-100 pb-75">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-sm-6">
                <div class="footer-widget">
                    <a routerLink="/" class="footer-logo">
                        <img src="assets/images/logo.png" alt="logo">
                    </a>
                    <p class="mb-4 pt-4"></p>
                    <ul class="social-link">
                        <li>
                            <a href="https://www.facebook.com/MireyaBeltranSalon" target="_blank">
                                <i class="ri-facebook-fill"></i>
                            </a>
                        </li>
                        <li>
                            <a href="https://x.com/mireyabsalonspa" target="_blank">
                              <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24"><path fill="currentColor" d="M18.205 2.25h3.308l-7.227 8.26l8.502 11.24H16.13l-5.214-6.817L4.95 21.75H1.64l7.73-8.835L1.215 2.25H8.04l4.713 6.231zm-1.161 17.52h1.833L7.045 4.126H5.078z"/></svg>
                            </a>
                        </li>
                        <li>
                            <a href="https://mx.linkedin.com/company/mireya-beltran-salon-spa" target="_blank">
                                <i class="ri-linkedin-fill"></i>
                            </a>
                        </li>
                        <li>
                            <a href="https://www.youtube.com/@MireyaBeltranSalonSPA" target="_blank">
                                <i class="ri-youtube-fill"></i>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6">
                <div class="footer-widget pe-2">
                    <h3>Mireya Beltran Salon & SPA</h3> 
                    <form class="newsletter-form">
                        <p>Paseo Malecon San Jose Lote 5 Zona Hotelera, Plaza Anexo Ziva 1er Piso, Depto # 2, Zona Hotelera Centro, 23405 San Jose del Cabo, Los Cabos, B.C.S.</p>
                    </form>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6">
                <div class="footer-widget ps-5">
                    <h3>{{ 'footer.hour' | translate}}</h3>
                    <ul class="salon-hours">
                        <li>
                            <h3>{{ 'footer.week' | translate}}</h3>
                            <span>09:00 a.m. - 08:00 p.m. </span>
                        </li>
                        <li>
                            <h3>{{ 'footer.saturday' | translate}}</h3>
                            <span>09:00 a.m. - 05:00 p.m. </span>
                        </li>
                        <li>
                            <h3>{{ 'footer.sunday' | translate}}</h3>
                            <span>{{ 'footer.close' | translate}}</span>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6">
                <div class="footer-widget">
                    <h3>{{ 'footer.contact' | translate}}</h3>
                    <ul class="footer-contact">
                        <li>
                            <i class="flaticon-020-phone-call"></i>
                            <h4>{{ 'footer.phone' | translate}}</h4>
                            <span>+52 (624) 142 4451</span>
                        </li>
                        <li>
                            <i class="flaticon-010-whatsapp"></i>
                            <h4>WhatsApp:</h4>
                            <span><a href="tel:+526241227019" target="_blank">+52 (624) 122 7019</a></span>
                        </li>
                        <li>
                            <i class="flaticon-195-envelope"></i>
                            <h4>{{ 'footer.mail' | translate}}</h4>
                            <span class="mail"><a href="mailto:info@mireyabeltransalon.com" target="_blank">info@mireyabeltransalon.com</a></span>
                        </li>
                       
                    </ul>
                </div>
            </div>
            
        </div>
    </div>
</footer>

<!-- Copyright -->
<div class="copyright-area">
    <div class="container">
        <p>© {{ year }} Mireya Beltran Salon. {{ 'footer.dev' | translate}}<a href="https://webanta.com/" target="_blank">Webanta</a></p>
    </div>
</div>
<div class="loading">
    <div class="scissors">
      <div class="part">
        <div class="hilt">
          <div class="inner-circle"></div>
        </div>
        <div class="blade"></div>
      </div>
      <div class="part">
        <div class="hilt">
          <div class="inner-circle"></div>
        </div>
        <div class="blade"></div>
      </div>
    </div>
    <div class="wrap">
      <div class="page">
        <div class="clipping"></div>
        <div class="clipping"></div>
      </div>
      <div class="page">
        <div class="line"></div>
      </div>
    </div>
  </div>
  
<!-- Inner Banner -->
<div class="inner-banner">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-lg-7 col-md-8">
                <div class="inner-title">
                    <h2>{{ 'page.inner_about' | translate }}</h2>
                    <ul>
                        <li>
                            <a routerLink="/">{{ 'page.inner_home' | translate }}</a>
                        </li>
                        <li>{{ 'page.inner_about' | translate }}</li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-5 col-md-4">
                <div class="inner-img">
                    <img src="assets/images/inner-banner/inner-banner1.png" alt="inner-banner" />
                </div>
            </div>
        </div>
    </div>
</div>

<!-- About -->
<!--<div class="about-area pt-100 pb-75">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="about-item">
                    <div class="about-slider">
                        <div class="top-border"></div>
                        <div class="bottom-border"></div>
                        <owl-carousel-o [options]="imageSlides">
                            <ng-template carouselSlide>
                                <div class="about-img">
                                    <img src="assets/images/about/about-img1.jpg" alt="about-image" />
                                </div>
                            </ng-template>
                            <ng-template carouselSlide>
                                <div class="about-img">
                                    <img src="assets/images/about/about-img2.jpg" alt="about-image" />
                                </div>
                            </ng-template>
                            <ng-template carouselSlide>
                                <div class="about-img">
                                    <img src="assets/images/about/about-img3.jpg" alt="about-image" />
                                </div>
                            </ng-template>
                        </owl-carousel-o>
                    </div>
                    <div class="about-vector">
                        <img src="assets/images/about/about-vector.png" alt="About" />
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="about-content">
                    <div class="section-title">
                        <span class="sub-title">About us</span>
                        <h2>Moisturize Your Hands Everyday</h2>
                        <p>Lorem Ipsum has been the industry standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled but also the leap into electronic typesetting.</p>
                        <p>Lorem Ipsum has been the industry standard dummy text ever since the 1500s, when an unknown printer took a galley of type.</p>
                        <p>Lorem Ipsum has been the industry standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled but also the leap into electronic typesetting.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->

<div
    class="about-area section-bg pt-100 pb-75">
    <div class="container">
        <div
            class="row align-items-center"
            *ngFor="let content of aboutCard">
            <div class="col-lg-6 col-md-12">
                <div class="about-item">
                    <div class="about-slider">
                        <div class="top-border"></div>
                        <div class="bottom-border"></div>
                        <owl-carousel-o [options]="imageSlides">
                            <ng-template
                                carouselSlide
                                *ngFor="let image of content.image">
                                <div class="about-img">
                                    <img [src]="image.img" alt="about-image" />
                                </div>
                            </ng-template>
                        </owl-carousel-o>
                    </div>
                    <div class="about-vector">
                        <img src="assets/images/about/about-vector.png" alt="About" />
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="about-content">
                    <div class="section-title">
                        <span class="sub-title">{{content.subtitle}}</span>
                        <h2>{{content.title}}</h2>
                        <p>{{content.desc1}}</p>
                        <p>{{content.desc2}}</p>

                        <span class="sub-title">{{content.title3}}</span>
                        <h2>{{content.title2}}</h2>
                        <p>{{content.mision}}</p>
                        <p>{{content.vision}}</p>
                        <p>{{content.valores}}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<app-intro-video></app-intro-video>

<app-appointment></app-appointment>

<app-feedback></app-feedback>

<app-staff></app-staff>
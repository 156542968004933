<!-- Navbar Area -->
<div
    class="navbar-area"
    [ngClass]="{'navbar-style-two': router.url === '/index-2' || router.url === '/index-3', 'sticky': isSticky}">
    <div class="container-fluid">
        <nav class="navbar navbar-expand-lg navbar-light bg-light" [class.active]="classApplied">
            <a class="navbar-brand" routerLink="/">
                <img src="assets/images/logo.png" alt="logo">
            </a>
            <button class="navbar-toggler" type="button" (click)="toggleClass()">
                <span class="burger-menu">
                    <span class="top-bar"></span>
                    <span class="middle-bar"></span>
                    <span class="bottom-bar"></span>
                </span>
            </button>
            <div class="collapse navbar-collapse">
                <ul class="navbar-nav">
                    <li class="nav-item">
                        <a routerLink="/" class=" nav-link">
                            {{ 'navbar.home' | translate }}
                        </a>
                    </li>
                    <li class="nav-item">
                        <a routerLink="/about" class="dropdown-toggle nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                            {{ 'navbar.about' | translate }}
                        </a>
                        <ul class="dropdown-menu">
                            <li class="nav-item">
                                <a routerLink="/testimonials" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                                    {{ 'navbar.testimonials' | translate }}
                                </a>
                            </li>
                            <li class="nav-item">
                                <a routerLink="/faq" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                                    {{ 'navbar.faqs' | translate }}
                                </a>
                            </li>
                            <li class="nav-item">
                                <a routerLink="/appointment" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                                    {{ 'navbar.book' | translate }}
                                </a>
                            </li>
                            <li class="nav-item">
                                <a routerLink="/privacy-policy" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                                    {{ 'navbar.privacy' | translate }}
                                </a>
                            </li>
                            <li class="nav-item">
                                <a routerLink="/terms-conditions" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                                    {{ 'navbar.terms' | translate }}
                                </a>
                            </li>   
                        </ul>
                    </li>
                    <li class="nav-item">
                        <a routerLink="/services" class="dropdown-toggle nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                            {{ 'navbar.serv' | translate }}
                        </a>
                        
                        <ul class="dropdown-menu">
                            
                            <li class="nav-item">
                                <a routerLink="/salon/dama" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                                    {{ 'navbar.serv_3' | translate }}
                                </a>
                            </li>
                            <li class="nav-item">
                                <a routerLink="/spa/limpieza" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                                    {{ 'navbar.serv_2' | translate }}
                                </a>
                            </li>
                            <li class="nav-item">
                                <a routerLink="/wedding/bodas" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                                    {{ 'navbar.serv_4' | translate }}
                                </a>
                            </li>

                            <li class="nav-item">
                                <a routerLink="/wellness/exfoliacion" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                                    {{ 'navbar.serv_1' | translate }}
                                </a>
                            </li>   

                        <!--   <li class="nav-item">
                                <a routerLink="/catalogo" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                                    {{ 'navbar.serv_5' | translate }}
                                </a>
                            </li>   -->
                        </ul>


                    </li>
                       <li class="nav-item">
                        <a routerLink="/contact" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                            {{ 'navbar.contact' | translate }}
                        </a>
                    </li>
                </ul>
                <div class="dropdown">
                    <a class="dropdown-toggle text" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                        {{currentLanguage.icon}}&nbsp;<span class="text"> {{currentLanguage.text}} &nbsp;</span>
                    </a>
                    <ul class="dropdown-menu">
                        <li><a class="dropdown-item" (click)="useLanguage('en')"> 🇺🇸&emsp;{{ "language.en" | translate
                                }}
                            </a></li>
                        <li><a class="dropdown-item" (click)="useLanguage('es')"> 🇲🇽&emsp;{{ "language.es" | translate
                                }}
                            </a></li>
                    </ul>
                </div>
<!--RESPONSIVE-->
                <ul class="navbar-nav for-responsive">
                    <li class="nav-item">
                        <a routerLink="/" href="javascript:void(0)" class=" nav-link">
                            {{ 'navbar.home' | translate }}
                        </a>                       
                    </li>
                    <li class="nav-item">
                        <a routerLink="/about" class="dropdown-toggle nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" (click)="toggleClass()">
                            {{ 'navbar.about' | translate }}
                        </a>
                        <ul class="dropdown-menu">
                            <li class="nav-item">
                                <a routerLink="/testimonials" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" (click)="toggleClass()">
                                    {{ 'navbar.testimonials' | translate }}
                                </a>
                            </li>
                            <li class="nav-item">
                                <a routerLink="/faq" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" (click)="toggleClass()">
                                    {{ 'navbar.faqs' | translate }}
                                </a>
                            </li>
                            <li class="nav-item">
                                <a routerLink="/appointment" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" (click)="toggleClass()">
                                    {{ 'navbar.book' | translate }}
                                </a>
                            </li>
                            <li class="nav-item">
                                <a routerLink="/privacy-policy" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" (click)="toggleClass()">
                                    {{ 'navbar.privacy' | translate }}
                                </a>
                            </li>
                            <li class="nav-item">
                                <a routerLink="/terms-conditions" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" (click)="toggleClass()">
                                    {{ 'navbar.terms' | translate }}
                                </a>
                            </li>
                            
                        </ul>
                    </li>                    
                    <li class="nav-item">
                        <a routerLink="/services" class="dropdown-toggle nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" (click)="toggleClass()">
                            {{ 'navbar.serv' | translate }}
                        </a> 
                        
                        <ul class="dropdown-menu">
                            
                            <li class="nav-item">
                                <a routerLink="/salon/dama" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                                    {{ 'navbar.serv_3' | translate }}
                                </a>
                            </li>
                            <li class="nav-item">
                                <a routerLink="/spa/limpieza" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                                    {{ 'navbar.serv_2' | translate }}
                                </a>
                            </li>
                            <li class="nav-item">
                                <a routerLink="/wedding/bodas" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                                    {{ 'navbar.serv_4' | translate }}
                                </a>
                            </li>

                            <li class="nav-item">
                                <a routerLink="/wellness/exfoliacion" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                                    {{ 'navbar.serv_1' | translate }}
                                </a>
                            </li> 
                          <!--  <li class="nav-item">
                                <a routerLink="/catalogo" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                                    {{ 'navbar.serv_5' | translate }}
                                </a>
                            </li>   -->
                        </ul>
                        

                    </li>  
                    <li class="nav-item">
                        <a routerLink="/contact" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" (click)="toggleClass()">
                            {{ 'navbar.contact' | translate }}
                        </a>
                    </li>
                </ul>
<!--END OF RESPONSIVE-->            
            </div>
        </nav>
    </div>
</div>


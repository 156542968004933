<!-- Inner Banner -->
<div class="inner-banner">
    <div class="container-fluid">
        <div
            class="row align-items-center">
            <div class="col-lg-7 col-md-8">
                <div class="inner-title">
                    <h2>{{ 'page.inner_services' | translate }}</h2>
                    <ul>
                        <li>
                            <a routerLink="/">{{ 'page.inner_home' | translate }}</a>
                        </li>
                        <li>{{ 'page.inner_services' | translate }}</li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-5 col-md-4">
                <div class="inner-img">
                    <img src="assets/images/inner-banner/inner-banner1.png" alt="inner-banner" />
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Services -->
<div class="services-area ptb-100">
    <div class="container">
        <div class="row">
            <div
                class="col-xl-4 col-lg-6 col-sm-6"
                *ngFor="let content of servicesCard | paginate: { itemsPerPage: 12, currentPage }"
            >
                <div class="services-card">
                 <!--   <a [routerLink]="['/' + content.type, content.id]" class="d-block img">
                        <img [src]="content.image" alt="services-image" />
                    </a> -->
                    <div class="content">
                        <h3><a [routerLink]="['/' + content.type, content.id]">
                            {{content.title}}
                        </a></h3>
                        <a [routerLink]="['/' + content.type, content.id]" class="more-btn">
                            <i class="flaticon-040-right-arrow"></i>
                        </a>
                    </div>
                </div>
            </div>
            <div class="col-xl-12 col-lg-12 col-sm-12">
                <div class="pagination-area">
                    <pagination-controls previousLabel="" nextLabel="" (pageChange)="onPageChange($event)"></pagination-controls>
                </div>
            </div>
        </div>
    </div>
</div>
import { Component, OnInit, OnDestroy } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ViewportScroller } from '@angular/common';

@Component({
    selector: 'app-about-page',
    templateUrl: './about-page.component.html',
    styleUrls: ['./about-page.component.scss']
})
export class AboutPageComponent implements OnInit, OnDestroy {

    about: string = '';
    aboutCard: { 
        title: string;
        image: any;
        subtitle: string;
        title2: string;
        title3: string;
        desc1: string;
        desc2: string;
        image2: string;
        image3: string;
        image4: string;
        mision: string;
        vision: string,
        valores: string,
    }[] = [];
  
    translateSub: any;

    constructor(
        public router: Router,
        private _translateService: TranslateService, 
        private route: ActivatedRoute, 
        private scroller: ViewportScroller,
    ) { }

    ngOnInit() {
        this.translateSub = this._translateService.stream('aboutContent').subscribe(data => {
         
          this.aboutCard = data;
       
        });
      }

    imageSlides: OwlOptions = {
        items: 1,
		nav: false,
		loop: true,
		dots: true,
		autoplay: true,
		smartSpeed: 1000,
        animateIn: 'fadeIn',
        animateOut: 'fadeOut',
		autoplayHoverPause: true,
		navText: [
            '<i class="flaticon-029-back"></i>',
            '<i class="flaticon-030-next"></i>'
		]
    }


    scrollDown() {
        this.scroller.scrollToAnchor("firstSection");
    }
    
    ngOnDestroy() {
        if (this.translateSub) {
          this.translateSub.unsubscribe();
        }
    }

}
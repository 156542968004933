<!-- Inner Banner -->
<div class="inner-banner">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-lg-7 col-md-8">
                <div class="inner-title">
                    <h2>{{ 'page.inner_citas' | translate }}</h2>
                    <ul>
                        <li>
                            <a routerLink="/">{{ 'page.inner_home' | translate }}</a>
                        </li>
                        <li>{{ 'page.inner_citas' | translate }}</li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-5 col-md-4">
                <div class="inner-img">
                    <img src="assets/images/inner-banner/inner-banner4.png" alt="inner-banner" />
                </div>
            </div>
        </div>
    </div>
</div>

<app-appointment></app-appointment>
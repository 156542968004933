<div class="banner-slider" *ngIf="bannerCard.length > 0">
    <owl-carousel-o [options]="bannerSlides">
      <ng-template carouselSlide *ngFor="let content of bannerCard">
        <div class="banner-item" [ngStyle]="{'background-image': 'url(' + content.bgImage + ')'}">
          <div class="container-fluid">
            <div class="banner-content">
              <span class="sub-title">{{content.subtitle}}</span>
              <h1 class="color">{{content.title}}</h1>
              <p class="color">{{content.paragraph}}</p>
              <a [routerLink]="content.id" class="default-btn with-icon">
                {{content.buttonText}} <i class="flaticon-030-next"></i>
              </a>
            </div>
          </div>
        </div>
      </ng-template>
    </owl-carousel-o>
  </div>
  
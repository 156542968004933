import { Component, OnInit, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { CartService } from '../../../cart.service';
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

    currentLanguage: { text: string, icon: string } = { text: " ES ", icon: " 🇲🇽 " };
    translateSub: any;
    navItems: any;

    cartProducts = this.cartService.getItems();

    constructor(
        public router: Router,
        private cartService: CartService,
        public translate: TranslateService
    ) { }

    ngOnInit(): void {
        this.translateSub = this.translate.stream('header.navItems').subscribe((data: any) => {
            this.navItems = data;
          });
    }

    switcherClassApplied = false;
    switcherToggleClass() {
        this.switcherClassApplied = !this.switcherClassApplied;
    }

    searchClassApplied = false;
    searchToggleClass() {
        this.searchClassApplied = !this.searchClassApplied;
    }

    classApplied = false;
    toggleClass() {
        this.classApplied = !this.classApplied;
    }

    // Navbar Sticky
    isSticky: boolean = false;
    @HostListener('window:scroll', ['$event'])
    checkScroll() {
        const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
        if (scrollPosition >= 50) {
            this.isSticky = true;
        } else {
            this.isSticky = false;
        }
    }

    useLanguage(language: string): void {
        this.translate.use(language);
        this.currentLanguage = { text: language.toUpperCase(), icon: language === "es" ? " 🇲🇽 " : " 🇺🇸 " };
      }

      ngOnDestroy() {
        if (this.translateSub) {
          this.translateSub.unsubscribe();
        }
      }

}
<ng-template #template>
    <div class="modal-body text-center px-5">
        <button class="close-button" (click)="closeModal()">×</button>
        <img src="{{alertOpt.type==='success' ? 'assets/icons/success.png':'assets/icons/error.png'}}" alt=""/>
        <h3>{{alertOpt.title}}</h3>
        <p [innerHTML]="alertOpt.message" class="alert-message"></p>
    </div>
</ng-template> 




import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ViewportScroller } from '@angular/common';

@Component({
    selector: 'app-featured-services',
    templateUrl: './featured-services.component.html',
    styleUrls: ['./featured-services.component.scss']
})
export class FeaturedServicesComponent implements OnInit, OnDestroy {

    service: string = '';
    serviceCard: { 
        title: string; 
        icon: string; 
        image: any;
        id: string;
        type: any; 
    }[] = [];
  
    translateSub: any;
  
    constructor(
      private _translateService: TranslateService, 
      private route: ActivatedRoute, 
      private scroller: ViewportScroller, 
      private router: Router
    ) {}
  
    ngOnInit() {
      this.service = this.route.snapshot.params['id'];
    
      this.translateSub = this._translateService.stream('featuresCard').subscribe(data => {
        // Asignar todos los elementos a serviceCard sin filtrar
      //  this.serviceCard = data;
    
        // Opcionalmente, puedes manejar la navegación si no encuentras nada
     //   if (this.serviceCard.length === 0) {
     //     this.router.navigate(['not-found']);
     //   }
    //  });
    this.serviceCard = data.map((card: { type: any; }) => ({
      ...card,
      type: card.type // Asegúrate que cada objeto en `data` tenga un `type`
  }));
});
    }
    
  
    scrollDown() {
      this.scroller.scrollToAnchor("firstSection");
    }
  
    ngOnDestroy() {
      if (this.translateSub) {
        this.translateSub.unsubscribe();
      }
    }
}

<!-- Default Section -->
<div class="pricing-area section-bg pt-100 pb-75">
    <div class="container">
        <div class="section-title text-center">
            <span class="sub-title">{{ 'page.catalog_subtitle' | translate}}</span>
            <h2>{{ 'page.catalog_title' | translate}}</h2>
            
        </div>
        
        <div class="row">
            <!-- Iterar sobre subarrays en pricingCard -->
            <div class="col-lg-6 col-md-6 col-sm-6" *ngFor="let pricingGroup of pricingCard">
                <div class="pricing-card">
                    <ul>
                        <!-- Iterar sobre los elementos dentro de cada subarray -->
                        <li *ngFor="let pricing of pricingGroup">
                            <img [src]="pricing.image" alt="pricing" />
                            <div class="content">
                                <h3>{{pricing.title}}<span>{{pricing.price}}</span></h3>
                                <p>{{pricing.desc}}</p>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>

    <div class="col-xl-12 col-lg-12 col-sm-12">
        <div class="view-more-btn text-center">
            <a routerLink="/catalogo" class="default-btn with-icon">
                {{ 'page.catalog_button' | translate }} <i class="flaticon-030-next"></i>
            </a>
        </div>
    </div>

</div>


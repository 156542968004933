import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ViewportScroller } from '@angular/common';

@Component({
    selector: 'app-services-page-one',
    templateUrl: './services-page-one.component.html',
    styleUrls: ['./services-page-one.component.scss']
})
export class ServicesPageOneComponent implements OnInit, OnDestroy {

    services: string = '';
    servicesCard: { 
        title: string;
        image: any;
        id: any;
        type: string;
    }[] = [];
  
    translateSub: any;

    constructor(
        public router: Router,
        private _translateService: TranslateService, 
        private route: ActivatedRoute, 
        private scroller: ViewportScroller,
    ) { }

    ngOnInit() {
        this.services = this.route.snapshot.params['id'];
    
        this.translateSub = this._translateService.stream('servicesCard').subscribe(data => {
            this.servicesCard = data.map((card: { type: any; }) => ({
                ...card,
                type: card.type // Asegúrate que cada objeto en `data` tenga un `type`
            }));
        });
    }

    scrollDown() {
        this.scroller.scrollToAnchor("firstSection");
    }
    
    ngOnDestroy() {
        if (this.translateSub) {
          this.translateSub.unsubscribe();
        }
    }

    currentPage : any;
    onPageChange(page: number) {
        this.currentPage = page;
        window.scrollTo(0, 0);
    }

}
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ViewportScroller } from '@angular/common';
import { Subscription } from 'rxjs';

interface ServiceDetail {
  title: string;
  id: string;
  description: string;
  button: string;
  offers: string;
  coverImage: string;
  coverImage1: string;
  coverImage2: string;
}

@Component({
  selector: 'app-service-detail-page',
  templateUrl: './service-detail-page.component.html',
  styleUrls: ['./service-detail-page.component.scss']
})
export class ServiceDetailPageComponent implements OnInit, OnDestroy{

  serviceID: string = '';
  servicesCard: ServiceDetail[] = [];
  allServices: ServiceDetail[] = [];
  selectedService: ServiceDetail | undefined = undefined;
  translateSub: Subscription | undefined;
  routeParamsSub: Subscription | undefined;

  constructor(
    public router: Router,
    private translateService: TranslateService,
    private route: ActivatedRoute,
    private scroller: ViewportScroller
  ) {}

  ngOnInit() {
    this.translateSub = this.translateService.stream('detailServiceWedding.services').subscribe((data: ServiceDetail[]) => {
      
      this.servicesCard = data;
      this.allServices = data; // Almacenamos todos los servicios
      // Get param ID from URL
      this.routeParamsSub = this.route.params.subscribe(params => {
        this.serviceID = params['id'] || '';
        this.viewService(this.serviceID);
      });
    });
  }

  viewService(serviceID?: string) {
    this.selectedService = serviceID && serviceID !== '' ?
      this.allServices.filter(service => service.id === serviceID)[0] :
      this.allServices[0];
  }

  scrollDown() {
    this.scroller.scrollToAnchor("firstSection");
  }

  ngOnDestroy() {
    if (this.translateSub) {
      this.translateSub.unsubscribe();
      this.routeParamsSub?.unsubscribe();
    }
  }

}

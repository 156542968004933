<div class="features-area pt-100">
    <div class="container">
        <div class="section-title text-center">
            <span class="sub-title">{{ 'page.featserv_subtitle' | translate }}</span>
            <h2>{{ 'page.featserv_title' | translate }}</h2>
            <div class="section-vector">
                <img src="assets/images/shape/section-vector.png" alt="vector" />
            </div>
        </div>
        <div class="d-flex flex-wrap">
            <!-- Utilizar *ngFor directamente sobre serviceCard -->
            <div class="col-lg-4 col-md-6 col-sm-6 local" *ngFor="let service of serviceCard">
                <div class="features-card">
                    <div class="img">
                        <img [src]="service.image" alt="features" />
                    </div>
                    <div class="content">
                        <div class="top-icon">
                            <i class={{service.icon}}></i>
                        </div>
                        <h3>
                            <a [routerLink]="['/' + service.type, service.id]">
                                {{service.title}}
                            </a>
                        </h3>
                        <a [routerLink]="['/' + service.type, service.id]" class="more-btn">
                            <i class="flaticon-040-right-arrow"></i>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

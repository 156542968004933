import { OwlOptions } from 'ngx-owl-carousel-o';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ViewportScroller } from '@angular/common';

@Component({
    selector: 'app-testimonials-page',
    templateUrl: './testimonials-page.component.html',
    styleUrls: ['./testimonials-page.component.scss']
})
export class TestimonialsPageComponent implements OnInit {

    testimonio: string = '';
    testimonioCard: { 
        name: string; 
        designation: string;
        desc: string;
        rating: any; 
        img: any; 
    }[] = [];
  
    translateSub: any;

    constructor(
        public router: Router,
        private _translateService: TranslateService, 
        private route: ActivatedRoute, 
        private scroller: ViewportScroller, 
    ) { }

    ngOnInit() {
        this.testimonio = this.route.snapshot.params['id'];
    
        this.translateSub = this._translateService.stream('testimonioCard').subscribe(data => {
          const foundCards = data.filter((el: any) => el.id === this.testimonio);
          if (foundCards.length > 0) {
            this.testimonioCard = foundCards;
          } else {
            this.router.navigate(['not-found']);
          }
        });
      }

      scrollDown() {
        this.scroller.scrollToAnchor("firstSection");
      }
    
      ngOnDestroy() {
        if (this.translateSub) {
          this.translateSub.unsubscribe();
        }
      }
    

    


}
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NotFoundComponent } from './components/common/not-found/not-found.component';
import { AboutPageComponent } from './components/pages/about-page/about-page.component';
import { AppointmentPageComponent } from './components/pages/appointment-page/appointment-page.component';
import { ContactPageComponent } from './components/pages/contact-page/contact-page.component';
import { FaqPageComponent } from './components/pages/faq-page/faq-page.component';
import { HomeDemoThreeComponent } from './components/pages/home-demo-three/home-demo-three.component';
import { PrivacyPolicyPageComponent } from './components/pages/privacy-policy-page/privacy-policy-page.component';
import { ServiceDetailsPageComponent } from './components/pages/service-details-page/service-details-page.component';
import { ServicesPageOneComponent } from './components/pages/services-page-one/services-page-one.component';
import { TermsConditionsPageComponent } from './components/pages/terms-conditions-page/terms-conditions-page.component';
import { TestimonialsPageComponent } from './components/pages/testimonials-page/testimonials-page.component';
import { PackagesDetailComponent } from './components/pages/packages-detail/packages-detail.component';
import { ServiceDetailPagesComponent } from './components/pages/service-detail-pages/service-detail-pages.component';
import { ServiceDetailsPagesComponent } from './components/pages/service-details-pages/service-details-pages.component';
import { ServiceDetailPageComponent } from './components/pages/service-detail-page/service-detail-page.component';


const routes: Routes = [
    {path: '', component: HomeDemoThreeComponent},
    {path: 'about', component: AboutPageComponent},
   // {path: 'catalogo', component: PackagesDetailComponent},
    {path: 'testimonials', component: TestimonialsPageComponent},
    {path: 'faq', component: FaqPageComponent},
    {path: 'appointment', component: AppointmentPageComponent},
    {path: 'services', component: ServicesPageOneComponent},
    {path: 'wellness/:id', component: ServiceDetailsPageComponent},
    {path: 'salon/:id', component: ServiceDetailPagesComponent},
    {path: 'spa/:id', component: ServiceDetailsPagesComponent},
    {path: 'wedding/:id', component: ServiceDetailPageComponent},
    {path: 'privacy-policy', component: PrivacyPolicyPageComponent},
    {path: 'terms-conditions', component: TermsConditionsPageComponent},
    {path: 'contact', component: ContactPageComponent},

    // Here add new pages component

    {path: '**', component: NotFoundComponent} // This line will remain down from the whole pages component list
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled', anchorScrolling: 'enabled',})],
    exports: [RouterModule]
})
export class AppRoutingModule { }
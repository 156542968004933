import { OwlOptions } from 'ngx-owl-carousel-o';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ViewportScroller } from '@angular/common';

@Component({
    selector: 'app-feedback',
    templateUrl: './feedback.component.html',
    styleUrls: ['./feedback.component.scss']
})
export class FeedbackComponent implements OnInit {

    testimonial: string = '';
    testimonialCard: { 
        name: string; 
        designation: string;
        desc: string;
        rating: any; 
        img: any; 
    }[] = [];
  
    translateSub: any;

    constructor(
        public router: Router,
        private _translateService: TranslateService, 
        private route: ActivatedRoute, 
        private scroller: ViewportScroller, 
    ) { }

    ngOnInit() {
        this.testimonial = this.route.snapshot.params['id'];
    
        this.translateSub = this._translateService.stream('testimonialCard').subscribe(data => {
          const foundCards = data.filter((el: any) => el.id === this.testimonial);
          if (foundCards.length > 0) {
            this.testimonialCard = foundCards;
          } else {
            this.router.navigate(['not-found']);
          }
        });
      }

    testimonialSlides: OwlOptions = {
		nav: false,
		margin: 25,
		loop: true,
		dots: false,
		autoplay: true,
		smartSpeed: 1000,
		autoplayHoverPause: true,
		navText: [
            '<i class="flaticon-029-back"></i>',
            '<i class="flaticon-030-next"></i>'
		],
        responsive: {
            0: {
                items: 1
            },
            515: {
                items: 2
            },
            695: {
                items: 2
            },
            935: {
                items: 2
            },
            1200: {
                items: 3
            }
        }
    }
    testimonialSlidesTwo: OwlOptions = {
		nav: false,
		margin: 25,
		loop: true,
		dots: false,
		autoplay: true,
		smartSpeed: 1000,
		autoplayHoverPause: true,
		navText: [
            '<i class="flaticon-029-back"></i>',
            '<i class="flaticon-030-next"></i>'
		],
        responsive: {
            0: {
                items: 1
            },
            515: {
                items: 1
            },
            695: {
                items: 2
            },
            935: {
                items: 2
            },
            1200: {
                items: 2
            }
        }
    }
    testimonialSlidesThree: OwlOptions = {
		nav: true,
		margin: 25,
		loop: true,
		dots: false,
        center: true,
		autoplay: true,
		smartSpeed: 1000,
		autoplayHoverPause: true,
		navText: [
            '<i class="flaticon-029-back"></i>',
            '<i class="flaticon-030-next"></i>'
		],
        responsive: {
            0: {
                items: 1
            },
            515: {
                items: 1
            },
            695: {
                items: 2
            },
            935: {
                items: 2
            },
            1200: {
                items: 2
            }
        }
    }

    scrollDown() {
        this.scroller.scrollToAnchor("firstSection");
      }
    
      ngOnDestroy() {
        if (this.translateSub) {
          this.translateSub.unsubscribe();
        }
      }


}
<!-- Inner Banner -->
<div class="inner-banner">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-lg-7 col-md-8">
                <div class="inner-title">
                    <h2>{{ 'page.inner_contact' | translate }}</h2>
                    <ul>
                        <li>
                            <a routerLink="/">{{ 'page.inner_home' | translate }}</a>
                        </li>
                        <li>{{ 'page.inner_contact' | translate }}</li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-5 col-md-4">
                <div class="inner-img">
                    <img src="assets/images/inner-banner/inner-banner5.png" alt="inner-banner" />
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Contact Info -->

    
<div class="section-title text-center pt-4 mt-4">
    <h2>{{ 'page.inner_contact' | translate }}</h2>
</div>

<div class="contact-information-area ptb-25">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-4 col-sm-6 ">
                <div class="contact-info-card">
                    <i class="flaticon-195-envelope"></i>
                    <h3>{{ 'page.contact_one' | translate }}</h3>
                    <p><a href="mailto:info@mireyabeltransalon.com">info@mireyabeltransalon.com</a></p>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6">
                <div class="contact-info-card">
                    <i class="flaticon-020-phone-call"></i>
                    <h3>{{ 'page.contact_two' | translate }}</h3>
                    <p><a href="tel:+526241227019">+52 (624) 122 7019</a></p>
                    <p><a href="tel:+526241424451">+52 (624) 142 4451</a></p>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6">
                <div class="contact-info-card">
                    <i class="flaticon-025-placeholder"></i>
                    <h3>{{ 'page.contact_three' | translate }}</h3>
                    <p>Paseo Malecon San José, Lote 5, Plaza Anexo Ziva 1er Piso, Depto. 2, Zona Hotelera Centro, ZIP Code 23405, San José del Cabo, Los Cabos, Baja California Sur, México.</p>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="text-center pb-5 mb-5" >
    <div id="map-container"></div>
    
</div>

<!-- Contact -->
<div class="contact-widget-area pb-100">
    <div class="container">
        <div class="section-title text-center">
            <h2>{{ 'page.contact_subtitle' | translate }}</h2>
        </div>

        <form [formGroup]="contactoForm" (ngSubmit)="contacto()" id="contacto-form" action="#" method="POST">
            <div class="contact-form">
                <div class="row">
                    <div class="col-lg-6 col-md-6">
                        <div class="form-group">
                            
                            <input id="name" [class.is-inpt-invalid]="isNameValid" autocomplete="off" formControlName="name" type="text" class="form-control" placeholder="{{ 'appointment.name' | translate }}">
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6">
                        <div class="form-group">
                            
                            <input id="email" [class.is-inpt-invalid]="isEmailValid" autocomplete="off" formControlName="email" type="email" class="form-control" placeholder="{{ 'appointment.mail' | translate }}">
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6">
                        <div class="form-group">
                            
                            <input class="form-control" id="phone" autocomplete="off" [class.is-inpt-invalid]="isPhoneValid" formControlName="phone" type="text" class="form-control" placeholder="{{ 'page.contact_place_phone' | translate }}">
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6">
                        <div class="form-group">
                            
                            <input id="subject" [class.is-inpt-invalid]="isSubjectValid" formControlName="subject" type="text" class="form-control" placeholder="{{ 'page.contact_place_subject' | translate }}">
                        </div>
                    </div>
                    <div class="col-lg-12 col-md-12">
                        <div class="form-group">
                            
                            <textarea class="form-control" id="message" [class.is-inpt-invalid]="isMessageValid" formControlName="message" name="message" class="form-control" cols="30" rows="7" placeholder="{{ 'page.contact_place_message' | translate }}"></textarea>
                        </div>
                    </div>
                    <div class="pb-4 captcha text-center">
                        {{ "captcha.one" | translate}}
                        <a href="https://policies.google.com/privacy" target="_blank">{{ "captcha.two" | translate}}</a> {{
                        "captcha.three" | translate}}
                        <a href="https://policies.google.com/terms" target="_blank">{{ "captcha.four" | translate}}</a> {{
                        "captcha.five" | translate}}
                    </div>
                  <!--  <div class="col-lg-12 col-md-12">
                        <div class="form-check">
                            <input type="checkbox" class="form-check-input" id="me">
                            <label class="form-check-label" for="me">{{ 'page.contact_place_terms1' | translate }} <a routerLink="/terms-conditions">{{ 'page.contact_place_terms2' | translate }}</a> {{ 'page.contact_place_terms3' | translate }} <a routerLink="/privacy-policy">{{ 'page.contact_place_terms4' | translate }}</a></label>
                        </div>
                    </div> -->
                    <div class="col-lg-12 col-md-12 text-center">
                        <button type="submit" class="default-btn">{{ 'page.contact_place_button' | translate }}</button>
                    </div>
                </div>
            </div>
        </form>
        
    </div>
</div>

<!--MODAL-->

<app-alert></app-alert>
<!-- Inner Banner -->
<div class="inner-banner">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-lg-7 col-md-8">
                <div class="inner-title">
                    <h2>Customer Service</h2>
                    <ul>
                        <li>
                            <a routerLink="/">Home</a>
                        </li>
                        <li>Customer Service</li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-5 col-md-4">
                <div class="inner-img">
                    <img src="assets/images/inner-banner/inner-banner12.png" alt="inner-banner" />
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Customer Service -->
<div class="customer-service-area ptb-100">
    <div class="container">
        <div class="customer-service-content">
            <img src="assets/images/customer-services.png" alt="customer-services">
            <h4>Connect Easily With Us</h4>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse quis neque euismod, egestas enim nec, ultricies lectus. Nam pretium orci ac mollis viverra. Maecenas eget lacinia nisl, vitae malesuada purus. Maecenas vel nulla quam. Maecenas maximus lectus non dapibus commodo. Duis faucibus quis dui quis vulputate.</p>
            <a routerLink="/contact" class="default-btn">
                Contact Us
            </a>
            <h4>Secure Payment</h4>
            <ul>
                <li>Items returned within 14 days of their original shipment date in same as new condition will be eligible for a full refund or store credit. Refunds will be charged back to the original form of payment used for purchase. Customer is responsible for shipping charges when making returns and shipping/handling fees of original purchase is non-refundable.</li>
                <li>MB Salon features a Fast Checkout option, allowing you to securely save your credit card details so that you don't have to re-enter them for future purchases.</li>
                <li>PayPal: Shop easily online without having to enter your credit card details on the website.Your account will be charged once the order is completed. To register for a PayPal account, visit the website paypal.com.</li>
            </ul>
            <h4>Global Shopping</h4>
            <p>This site is provided by <strong>MB Salon</strong>. <strong>MB Salon</strong> collects information in several ways from different parts of this site.</p>
            <p><strong>1.</strong> Complimentary ground shipping within 1 to 7 business days<br>
                <strong>2.</strong> In-store collection available within 1 to 7 business days<br>
                <strong>3.</strong> Next-day and Express delivery options also available<br>
                <strong>4.</strong> Purchases are delivered in an orange box tied with a Bolduc ribbon, with the exception of certain items<br>
                <strong>5.</strong> See the delivery FAQs for details on shipping methods, costs and delivery times
            </p>
            <h4>Easy Return</h4>
            <p>Items returned within 14 days of their original shipment date in same as new condition will be eligible for a full refund or store credit. Refunds will be charged back to the original form of payment used for purchase. Customer is responsible for shipping charges when making returns and shipping/handling fees of original purchase is non-refundable.</p>
        </div>
    </div>
</div>
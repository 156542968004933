<!-- Default Section -->
<div class="services-area ptb-100">
    <div class="container">
        <div class="section-title text-center">
            <span class="sub-title">{{ 'page.subtitle' | translate }}</span>
            <h2>{{ 'page.title' | translate }}</h2>
            <div class="section-vector">
                <img src="assets/images/shape/section-vector.png" alt="vector" />
            </div>
        </div>
        <div class="row">
            <div class="col-xl-6 col-lg-6 col-sm-6" *ngFor="let content of servicesCard">
                <div class="services-card">
                    <a [routerLink]="['/' + content.type, content.id]" class="d-block img">
                        <img [src]="content.image" alt="services-image" />
                    </a>
                    <div class="content">
                        <h3>
                            <a [routerLink]="['/' + content.type, content.id]">
                                {{content.title}}
                            </a>
                        </h3>
                        <a [routerLink]="['/' + content.type, content.id]" class="more-btn">
                            <i class="flaticon-040-right-arrow"></i>
                        </a>
                    </div>
                </div>
            </div>
            <div class="col-xl-12 col-lg-12 col-sm-12">
                <div class="view-more-btn text-center">
                    <a routerLink="/services" class="default-btn with-icon">
                        {{ 'page.button' | translate }} <i class="flaticon-030-next"></i>
                    </a>
                </div>
            </div>
        </div>
        <div class="services-vector">
            <img src="assets/images/services/services-vector.png" alt="vector" />
        </div>
    </div>
</div>

<div class="banner-form-area">
    <div class="container">
        
        <form [formGroup]="contactForm" (ngSubmit)="banner()" id="contact-form" action="#" method="POST">
            <div class="banner-form">
                <div class="row">
                    <div class="col-lg-3 col-sm-6">
                        <div class="form-group form-group-list">
                            <div class="form-icon">
                                <i class="flaticon-025-placeholder"></i>
                            </div>
                            <label>{{ 'banner-form.type' | translate}}</label>
                            <select class="form-select" [class.is-inpt-invalid]="isTypeValid" id="type" formControlName="type">
                                <option selected disabled>{{ 'banner-form.type' | translate}}</option>
                                <option value="sitio">{{ 'banner-form.site' | translate}}</option>
                                <option value="domicilio">{{ 'banner-form.dom' | translate}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-lg-3 col-sm-6">
                        <div class="form-group form-group-list">
                            <div class="form-icon">
                                <i class="flaticon-058-user"></i>
                            </div>
                            <label>{{ 'banner-form.service' | translate}}</label>
                            <select [class.is-inpt-invalid]="isServiceValid" class="form-select" id="service" formControlName="service">
                                <option selected disabled>{{ 'banner-form.service' | translate}}</option>
                                <option value="salon">{{ 'banner-form.s1' | translate}}</option>
                                <option value="peinado_maquillaje">{{ 'banner-form.s2' | translate}}</option>
                                <option value="color">{{ 'banner-form.s3' | translate}}</option>
                                <option value="cera">{{ 'banner-form.s4' | translate}}</option>
                                <option value="tratamientos">{{ 'banner-form.s5' | translate}}</option>
                                <option value="spa">{{ 'banner-form.s6' | translate}}</option>
                                <option value="manicure">{{ 'banner-form.s7' | translate}}</option>
                                <option value="pedicure">{{ 'banner-form.s8' | translate}}</option>
                                <option value="bodas">{{ 'banner-form.s9' | translate}}</option>
                                <option value="bienestar">{{ 'banner-form.s10' | translate}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-lg-3 col-sm-6">
                        <div class="form-group form-group-list">
                            <div class="form-icon">
                                <i class="flaticon-046-calendar"></i>
                            </div>
                            <label>{{ 'banner-form.date' | translate}}</label>
                            <input id="date" [class.is-inpt-invalid]="isDateValid" formControlName="date" type="text" placeholder="{{ 'banner-form.place_date' | translate}}" bsDatepicker>
                        </div>
                    </div>
                    <div class="col-lg-3 col-sm-6">
                        <div class="form-group form-group-list">
                            <div class="form-icon">
                                <i class="flaticon-048-clock"></i>
                            </div>
                            <label>{{ 'banner-form.time' | translate}}</label>
                            <input id="hour" [class.is-inpt-invalid]="isHourValid" formControlName="hour" type="time" placeholder="{{ 'banner-form.place_date' | translate}}">
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-12 col-sm-12 top">
                        <div class="form-group">
                            <input id="email" [class.is-inpt-invalid]="isEmailValid" autocomplete="off" formControlName="email" type="email" class="form-control" placeholder="{{ 'banner-form.place_mail' | translate}}">
                        </div>
                    </div>

                    <div class="col-lg-3 col-md-6 col-sm-6">
                        <div class="form-group">
                            <input id="name" [class.is-inpt-invalid]="isNameValid" autocomplete="off" formControlName="name" type="name" class="form-control" placeholder="{{ 'banner-form.place_name' | translate}}">
                        </div>
                    </div>

                    <div class="col-lg-3 col-md-6 col-sm-6">
                        <div class="form-group">
                            <input id="phone" autocomplete="off" [class.is-inpt-invalid]="isPhoneValid" formControlName="phone" type="text" class="form-control" placeholder="{{ 'banner-form.place_phone' | translate}}">
                        </div>
                    </div>
                    <div class="col-lg-2 col-md-12 col-sm-12">
                        <div class="form-group">
                            <button type="submit" class="default-btn">
                                {{ 'banner-form.schedule' | translate}}
                            </button>
                        </div>
                    </div>
                    <div class="pb-4 col-12 text-center captcha">
                        {{ "captcha.one" | translate}}
                        <a href="https://policies.google.com/privacy" target="_blank" >{{ "captcha.two" | translate}}</a> {{
                        "captcha.three" | translate}}
                        <a href="https://policies.google.com/terms" target="_blank">{{ "captcha.four" | translate}}</a> {{
                        "captcha.five" | translate}}
                    </div>
                </div>
            </div>
        </form>
        
    </div>
</div>

<!--MODAL-->

<app-alert></app-alert>
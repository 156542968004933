import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ViewportScroller } from '@angular/common';

@Component({
    selector: 'app-staff',
    templateUrl: './staff.component.html',
    styleUrls: ['./staff.component.scss']
})
export class StaffComponent implements OnInit {

    team: string = '';
    teamContent: { 
        name: string; 
        designation: string;
        image: any;
        detailsLink: any;
        social: any;
    }[] = [];
  
    translateSub: any;

    constructor(
        public router: Router,
        private _translateService: TranslateService, 
        private route: ActivatedRoute, 
        private scroller: ViewportScroller, 
    ) { }

   
    ngOnInit() {
        this.team = this.route.snapshot.params['id'];
    
        this.translateSub = this._translateService.stream('teamContent').subscribe(data => {
          const foundCards = data.filter((el: any) => el.id === this.team);
          if (foundCards.length > 0) {
            this.teamContent = foundCards;
          } else {
            this.router.navigate(['not-found']);
          }
        });
      }

      scrollDown() {
        this.scroller.scrollToAnchor("firstSection");
      }
    
      ngOnDestroy() {
        if (this.translateSub) {
          this.translateSub.unsubscribe();
        }
      }
    

}
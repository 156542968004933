<!--BODAS FRONT SERVICES-->
<!-- Inner Banner -->
<div class="inner-banner">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-lg-7 col-md-8">
                <div class="inner-title">
                    <h2>{{ 'page.inner_title' | translate }} {{ 'page.inner_weddings' | translate }}</h2>
                    <ul>
                        <li>
                            <a routerLink="/">{{ 'page.inner_home' | translate }}</a>
                        </li>
                        <li>{{ 'page.inner_weddings' | translate }}</li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-5 col-md-4">
                <div class="inner-img">
                    <img src="assets/images/inner-banner/inner-banner4.png" alt="inner-banner" />
                </div>
            </div>
        </div>
    </div>
</div>


<div class="service-details-area pt-100 pb-75">
    <div class="container">
      <div class="row">
        <div class="col-lg-4 col-md-12">
          <div class="sidebar-area">
            <ul class="services-catagory">
              <li *ngFor="let service of servicesCard">
                <a [routerLink]="['/wedding', service.id]" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
                  {{ service.title }}
                  <i class="ri-arrow-right-s-line"></i>
                </a>
              </li>
            </ul>
            <div class="sidebar-widget">
              <h3>{{ 'page.menu' | translate }}</h3>
              <p>{{ 'page.menu_desc_sitio' | translate }}</p>
              <button class="default-btn" type="button">
                {{ 'page.menu_btn' | translate }}
              </button>
            </div>

            <div class="sidebar-widget">
              <h3>{{ 'page.menu' | translate }}</h3>
              <p>{{ 'page.menu_desc_dom' | translate }}</p>
              <button class="default-btn" type="button">
                {{ 'page.menu_btn' | translate }}
              </button>
            </div>
          </div>
        </div>
        
        <div class="col-lg-8 col-md-12">
          <div class="service-details-content" *ngIf="selectedService">
          <!--  <div class="service-preview-img" *ngIf="selectedService">
              <img [src]="selectedService.coverImage" alt="service-details" />
            </div> -->
            <h3>{{ selectedService.title }}</h3>
            <p>{{ selectedService.description }}</p>
          <!--  <div class="row">
              <div class="col-6" *ngIf="selectedService">
                <div class="service-preview-img">
                  <img [src]="selectedService.coverImage1" alt="service-details" />
                </div>
              </div>
              <div class="col-6" *ngIf="selectedService">
                <div class="service-preview-img">
                  <img [src]="selectedService.coverImage2" alt="service-details" />
                </div>
              </div>
            </div> -->
            <h3>{{ 'page.services' | translate }}</h3>
            <p [innerHTML]="selectedService.offers"></p>
          </div>
        </div>
      </div>
    </div>
  </div>

<!-- Contact -->
<div class="contact-widget-area pb-100">
    <div class="container">
        <div class="section-title text-center">
            <h2>{{ 'page.contact_subtitle' | translate }}</h2>
        </div>

        <form [formGroup]="contactoForm" (ngSubmit)="contacto()" id="contacto-form" action="#" method="POST">
            <div class="contact-form">
                <div class="row">
                    <div class="col-lg-6 col-md-6">
                        <div class="form-group">
                            
                            <input id="name" [class.is-inpt-invalid]="isNameValid" autocomplete="off" formControlName="name" type="text" class="form-control" placeholder="{{ 'appointment.name' | translate }}">
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6">
                        <div class="form-group">
                            
                            <input id="email" [class.is-inpt-invalid]="isEmailValid" autocomplete="off" formControlName="email" type="email" class="form-control" placeholder="{{ 'appointment.mail' | translate }}">
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6">
                        <div class="form-group">
                            
                            <input class="form-control" id="phone" autocomplete="off" [class.is-inpt-invalid]="isPhoneValid" formControlName="phone" type="text" class="form-control" placeholder="{{ 'page.contact_place_phone' | translate }}">
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6">
                        <div class="form-group">
                            
                            <input id="subject" [class.is-inpt-invalid]="isSubjectValid" formControlName="subject" type="text" class="form-control" placeholder="{{ 'page.contact_place_subject' | translate }}">
                        </div>
                    </div>
                    <div class="col-lg-12 col-md-12">
                        <div class="form-group">
                            
                            <textarea class="form-control" id="message" [class.is-inpt-invalid]="isMessageValid" formControlName="message" name="message" class="form-control" cols="30" rows="7" placeholder="{{ 'page.contact_place_message' | translate }}"></textarea>
                        </div>
                    </div>
                    <div class="pb-4 captcha text-center">
                        {{ "captcha.one" | translate}}
                        <a href="https://policies.google.com/privacy" target="_blank">{{ "captcha.two" | translate}}</a> {{
                        "captcha.three" | translate}}
                        <a href="https://policies.google.com/terms" target="_blank">{{ "captcha.four" | translate}}</a> {{
                        "captcha.five" | translate}}
                    </div>
                  <!--  <div class="col-lg-12 col-md-12">
                        <div class="form-check">
                            <input type="checkbox" class="form-check-input" id="me">
                            <label class="form-check-label" for="me">{{ 'page.contact_place_terms1' | translate }} <a routerLink="/terms-conditions">{{ 'page.contact_place_terms2' | translate }}</a> {{ 'page.contact_place_terms3' | translate }} <a routerLink="/privacy-policy">{{ 'page.contact_place_terms4' | translate }}</a></label>
                        </div>
                    </div> -->
                    <div class="col-lg-12 col-md-12 text-center">
                        <button type="submit" class="default-btn">{{ 'page.contact_place_button' | translate }}</button>
                    </div>
                </div>
            </div>
        </form>
        
    </div>
</div>

<!--MODAL-->

<app-alert></app-alert>